import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import ChatIcon from "./component";
import { setChatIconProperty } from "./actions";

const mapStateToProps = ({
    chatIcon: {
        iconProperties
    },
    carDetails,
    carEvaluation: {make},
    checkout: {
        currentStep
    }
}) => ({
    iconProperties,
    carDetails,
    checkoutStep: currentStep,
    make
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setChatIconPropertyConnect: setChatIconProperty
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatIcon));
